export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';
export const servicePathBookingOldUrl = 'https://bookings.berhampur.gov.in';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const apiUrl=`https://backend.dev.bemc.teceads.co.in/api/v1/`

// export const apiUrl=`http://localhost:4031/api/v1/`
export const staticFileUrl=`http://localhost:4031/`
// export const apiUrl=`http://192.168.1.10:4031/api/v1/`
 
export const apiSecret=`registeruser`

// export const token=`eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJhdmF0YXIiOiJodHRwczovL3d3dy53M3NjaG9vbHMuY29tL2hvd3RvL2ltZ19hdmF0YXIyLnBuZyIsImFsbG93ZWRfb3BlcmF0aW9ucyI6WyJhbGwiXSwiX2lkIjoiNjJkN2Y2ZWNjMjI0YjUzNmZjMzg1ZWY5IiwiZW1haWwiOiJzdXBlcmFkbWluQGJlbWMuY29tIiwiZmlyc3ROYW1lIjoiU3VwZXJBZG1pbiIsImxhc3ROYW1lIjoiVXNlciIsInBob25lTnVtYmVyIjo4MjUwNDYwOTIxLCJwYXNzd29yZCI6IiQyYiQxMCQ2QzhlM01KOW14eWMxck8vNy81QTFlazZRTVVUaHRHRy91NTdlMzZmbWdyYTRLOGt6a1lFMiIsInJvbGUiOiJzdXBlckFkbWluIiwiY3JlYXRlZF9ieSI6IlNpYmFzaXNoIFBhc3VwYWxhayIsInN0YXR1cyI6ImFjdGl2ZSIsImNyZWF0ZWRBdCI6IjIwMjItMDctMjBUMTI6Mzc6MDAuMzkwWiIsInVwZGF0ZWRBdCI6IjIwMjItMDctMjlUMDY6NDc6MDIuMzY0WiIsIl9fdiI6MH0sImlhdCI6MTY2MjgxNTI2OSwiZXhwIjoxNjYyOTg4MDY5fQ.rl8uM0sv_QkZKtNFqktZwVXkm2-NGf7gqctDctMRcx4`


export const getToken = async()=>{ 
  let token = await localStorage.getItem('data')
  token = token ? JSON.parse(token).token : ''
  return `${token}`
}
 